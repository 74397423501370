<template>
  <!-- 北美SDE求职旗舰班 -->
  <div class="vip-page">
    <div sticky-container>
      <div class="page-wrap">
        <div
          class="page-banner"
          :style="{ backgroundImage: 'url(' + img + ')' }"
        >
          <div class="container">
            <div>
              <pageSelect
                :br="br"
                :bantxt="bantxt"
                @changebr="(val) => (br = val)"
              ></pageSelect>
            </div>
            <div class="sch-title">北美SDE求职旗舰班</div>
            <div class="sch-desc">
              一门专为北美FMAG级别公司定制的面试课程<br />
              包含工业级项目，算法+设计体系，终身内推，1v1陪伴你直到拿到满意的Offer
            </div>
            <ul class="leet-date leet-sm-space">
<!--              <li>-->
<!--                <div class="val">7 <span>人</span></div>-->
<!--                <div class="txt">VIP小班</div>-->
<!--              </li>-->
              <li>
                <div class="val">1v1 <span>式</span></div>
                <div class="txt">全程规划指导</div>
              </li>
              <li>
                <div class="val">7 <span>大</span></div>
                <div class="txt">北美课程体系</div>
              </li>
              <li>
                <div class="val">250<span>+</span></div>
                <div class="txt">小时课程</div>
              </li>
              <li>
                <div class="val">1000 <span>道</span></div>
                <div class="txt">经典算法面试题</div>
              </li>
              <li>
                <div class="val">365 <span>天</span></div>
                <div class="txt">跟踪陪伴</div>
              </li>
            </ul>
            <!-- 视频 -->
            <div @click="videoShow = true" class="page-video">
<!--              <img src="../../assets/images/scb/shipin.png" alt="" />-->
              <img src="../../assets/images/scb/shipin-Edward.png" alt="" />
              <div class="v-icon"></div>
<!--              <div class="v-title">课程简介 & 免费试听</div>-->
            </div>
            <!--            <div class="countDownText" v-if="countDownTimeStamp != -1">-->
            <!--              <span class="text"-->
            <!--                >下一期开课：<span class="countsNum">{{ countDays }}</span-->
            <!--                >天<span class="countsNum">{{ countHours }}</span-->
            <!--                >小时<span class="countsNum">{{ countMinutes }}</span-->
            <!--                >分<span class="countsNum">{{ countSeconds }}</span-->
            <!--                >秒</span-->
            <!--              >-->
            <!--            </div>-->
          </div>
        </div>
        <poly-video
          v-if="videoShow"
          :br="br"
          @closeVideo="videoShow = false"
        ></poly-video>
        <div class="priceBanner">
          <div class="priceBannerArea">
            <div class="priceBannerContent">
<!--              <div-->
<!--                style="-->
<!--                  color: rgba(0, 0, 0, 0.65);-->
<!--                  text-align: center;-->
<!--                  font-size: 12px;-->
<!--                  position: absolute;-->
<!--                  right: 82px;-->
<!--                  top: 6px;-->
<!--                "-->
<!--              >-->
<!--                立即报名，立即开始！报名即学，不浪费一分一秒！-->
<!--              </div>-->
              <img src="@/assets/imgs/priceBanner.png" alt="" />
              <div class="priceBannerInner">
                <div class="priceLeft">
                  <div class="prices" v-if="comboData.length > 0">
                    <span style="font-size: 28px; color: #de1306">
<!--                      ${{-->
<!--                        comboData[0].usddiscountPrice != 0-->
<!--                          ? comboData[0].usddiscountPrice-->
<!--                          : comboData[0].usdprice-->
<!--                      }}-->
                      ${{comboData[0].usdprice}}
                    </span
                    >
                    <span style="font-size: 16px; color: #e02020"
                      >&nbsp;
<!--                      /&nbsp;&yen;{{-->
<!--                        comboData[0].discountPrice != 0-->
<!--                          ? comboData[0].discountPrice-->
<!--                          : comboData[0].price-->
<!--                      }}-->
<!--                      / &yen;50000-->
                      / &yen;{{comboData[0].price}}
                    </span
                    >
                  </div>
                  <div class="pricesTips">
                    <div class="dollar">支持美元付款</div>
                    <div class="huabei">支持花呗分期</div>
                  </div>
                </div>

                <div class="priceRight">
                  <el-button class="classHelp" @click="helpVisible = true"
                    >课程咨询</el-button
                  >
                  <el-button class="buyNow" @click="jumptoPrice"
                    >立即购买</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <helpClass
          :visible="helpVisible"
          @setHelpVisible="(val) => (helpVisible = val)"
        ></helpClass>

        <div
          class="sticky"
          v-sticky="stickyEnabled"
          sticky-offset="{top: 0, bottom: 30}"
          sticky-side="both"
          on-stick="onStick"
          sticky-z-index="99920"
        >
          <div class="page-navbar">
            <div class="container">
              <pageStickySelect
                :dr="dr"
                @changedr="(val) => (dr = val)"
              ></pageStickySelect>
              <Menus
                :menus="menuList"
                @TabActiveIndexChange="TabActiveIndexChange"
                :current-index="currentIndex"
              />
              <div v-if="stickyToggle" class="price" style="right: 75px">
                <span
                  style="font-size: 16px; color: #de1306"
                  v-if="comboData.length > 0"
                >
                  ${{comboData[0].usdprice }}
                </span>
                <span style="font-size: 12px; color: #e02020"
                  >
<!--                  /&yen;50000-->
                  /&yen;{{comboData[0].price }}
                </span
                >

                <div class="priceDetail" @click="jumptoPrice">价格详情</div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            TabActiveIndex === 'course_intro' ||
            TabActiveIndex === 'course_price'
          "
        >
          <div class="g-section">
            <div class="g-header">
              <h3>北美求职全课程体系，全程规划达到面试水平</h3>
              <p>在最短时间内，体系化学习SDE知识体系，具备面试能力</p>
            </div>
            <div class="container">
              <div class="tx-cells">
                <el-row :gutter="30">
                  <el-col :span="8">
                    <div class="rel-card hoverUp">
                      <div class="rel-pic">
                        <img src="../../assets/images/scb/shuip1.png" alt="" />
                      </div>
                      <div class="rel-grid">
                        <div class="rel-hd" style="font-size: medium">LeetCode 千题讲解</div>
                        <div class="rel-line"></div>
                        <div class="rel-desc">
                          <br />
                          千题讲解<br />
                          1000题目一道道讲解<br />
                          两个月400题，三个月500题
                        </div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="rel-card hoverUp">
                      <div class="rel-pic">
                        <img src="../../assets/images/scb/shuip2.png" alt="" />
                      </div>
                      <div class="rel-grid">
                        <div class="rel-hd" style="font-size: medium">项目课程</div>
                        <div class="rel-line"></div>
                        <div class="rel-desc">
                          <br />
                          包含工业级项目课<br />
                          完美解决简历没项目问题<br />
                          让你快速拿到面试机会
                        </div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="rel-card hoverUp">
                      <div class="rel-pic">
                        <img src="../../assets/images/scb/shuip3.png" alt="" />
                      </div>
                      <div class="rel-grid">
                        <div class="rel-hd" style="font-size: medium">终身内推</div>
                        <div class="rel-line"></div>
                        <div class="rel-desc">
                          <br />
                          北美10+公司内推<br />
                          强推免掉电面<br />
                          国内外资源，终身内推
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <div class="g-section cc-section">
            <div class="g-header">
              <h3>
                一站式求职服务，课程全部体系化，从小白到offer之路
              </h3>
              <p>以多年经验来论，只要认真跟上，offer近在咫尺</p>
            </div>
            <div class="container">
              <div class="row step-row">
                <div class="col">
                  <div class="row">
                    <div class="col-12">
                      <div class="sbox sbox-arrow" style="background-color: rgba(55,195,204,.3);">
                        <div>Stage1</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="sbox" style="background-color: rgba(55,195,204,.5);">
                        <div>定制规划</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="sbox style-ht1" style="background-color: rgba(55,195,204,.7);">
                        <div>评估现状<br />明确目标<br />求职规划</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="row">
                    <div class="col-12">
                      <div class="sbox step-box2 sbox-arrow" style="background-color: rgba(55,195,204,.4);">
                        <div>Stage2</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="sbox" style="background-color: rgba(55,195,204,.7);">
                        <div>项目课程</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="sbox style-ht1" style="background-color: rgba(55,195,204,1);">
                        <div>转专业<br />缺经验<br />工业级<br />项目课</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-diy-3">
                  <div class="row">
                    <div class="col-4">
                      <div class="sbox step-box3 sbox-arrow" style="background-color: rgba(56,137,200,.3);">
                        <div>Stage3</div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="sbox step-box4 sbox-arrow" style="background-color: rgba(56,137,200,.3);">
                        <div>Stage4</div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="sbox step-box5 sbox-arrow" style="background-color: rgba(56,137,200,.3);">
                        <div>Stage5</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="sbox style-ht2"  style="background-color: rgba(56,137,200,.7);">
                        <div>算法体系化</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      <div class="sbox style-ht3"  style="background-color: rgba(56,137,200,1);">
                        <div>算法<br />基础知识</div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="sbox style-ht4" style="background-color: rgba(56,137,200,1);">
                        <div>题型<br />技巧总结</div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="sbox style-ht5" style="background-color: rgba(56,137,200,1);">
                        <div>LeeCode<br />千题讲解</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="sbox style-ht6" style="background-color: rgba(56,137,200,1);">
                        <div>算法面试三部曲</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="sbox style-ht7" style="background-color: rgba(56,137,200,1);">
                        <div>突破公司算法面试</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-diy-3">
                  <div class="row">
                    <div class="col-4">
                      <div class="sbox step-box6 sbox-arrow" style="background-color: rgba(45,95,209,.5);">
                        <div>Stage6</div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="sbox step-box7 sbox-arrow" style="background-color: rgba(45,95,209,.5);">
                        <div>Stage7</div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="sbox step-box77 sbox-arrow" style="background-color: rgba(45,95,209,.5);">
                        <div>Stage8</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="sbox style-ht8" style="background-color: rgba(45,95,209,.5);">
                        <div>设计体系化</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      <div class="sbox style-ht3" style="background-color: rgba(45,95,209,1);">
                        <div>23种<br />设计模式</div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="sbox style-ht4" style="background-color: rgba(45,95,209,1);">
                        <div>OOD<br />对象设计</div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="sbox style-ht5" style="background-color: rgba(45,95,209,1);">
                        <div>系统<br />设计</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="sbox style-ht6" style="background-color: rgba(45,95,209,1);">
                        <div>设计面试三部曲</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="sbox style-ht7" style="background-color: rgba(45,95,209,1);">
                        <div>零基础打通设计之路</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-diy-2">
                  <div class="row">
                    <div class="col-6">
                      <div class="sbox step-box8 sbox-arrow" style="background-color: rgba(87,85,196,.5);">
                        <div>Stage9</div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="sbox step-box9 sbox-arrow" style="background-color: rgba(87,85,196,.5);">
                        <div>Stage10</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="sbox style-ht11" style="background-color: rgba(87,85,196,.7);">
                        <div>内推面试</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div class="sbox style-ht12" style="background-color: rgba(87,85,196,1);">
                        <div>简历修改<br />模拟面试</div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="sbox style-ht13" style="background-color: rgba(87,85,196,1);">
                        <div>北美10+公司<br />终身内推</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="row">
                    <div class="col-12">
                      <div class="sbox step-box10 sbox-arrow" style="background-color: rgba(196,85,145,.5);">
                        <div>Stage11</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="sbox" style="background-color: rgba(196,85,145,.7);">
                        <div>拿到offer</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="sbox style-ht1" style="background-color: rgba(196,85,145,1);">
                        <div>认真学习<br />拿到offer<br />近在咫尺</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ci-btn">
                <div class="ci-link">全程1v1答疑，1v1电话监督，随时联系导师</div>
              </div>
            </div>
          </div>
          <div class="g-section">
            <div class="g-header">
              <h3>解决简历没项目或者项目水问题，省下5000刀额外购买项目课程</h3>
              <p>推荐硬核，工业界项目课程，不再是Udemy，学校等水项目课程</p>
            </div>
            <div class="container">
              <el-row :gutter="40">
                <el-col :span="8">
                  <div class="keus-card hoverUp">
                    <img src="../../assets/images/beimeivip/proj-pic-1.png" >
                    <div class="ke-abs">
                      <div class="tt">2-3周</div>
                      <div class="desc">每个项目预计2-3周做完</div>
                    </div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="keus-card hoverUp">
                    <img src="../../assets/images/beimeivip/proj-pic-2.png" >
                    <div class="ke-abs">
                      <div class="tt">2个月左右</div>
                      <div class="desc">4个项目课程预计在2个月左右</div>
                    </div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="keus-card hoverUp">
                    <img src="../../assets/images/beimeivip/proj-pic-3.png" >
                    <div class="ke-abs">
                      <div class="tt">按需可选</div>
                      <div class="desc">按需可选，如已有项目或工作经验即可省略</div>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="g-section g-step-section">
            <div class="g-header">
              <h3>独特的体系化课程，体系自成一派，以绝对实用为主</h3>
              <p>算法体系足以突破算法面试，设计体系从小白教起到大神</p>
            </div>
            <div class="step-main">
              <div class="step-title">设计面试三部曲</div>
              <div class="container">
                <el-row>
                  <el-col :span="8">
                    <div class="step-third-box">
                      <div class="step-n">01</div>
                      <div class="step-cc">Gof 23种设计模式</div>
                      <div class="step-items">
                        <div class="item">极其实用有效的课程</div>
                        <div class="item">工作中会用到的知识</div>
                        <div class="item">面试中会考到的考点</div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="step-third-box">
                      <div class="step-n">02</div>
                      <div class="step-cc">面向对象设计OOD</div>
                      <div class="step-items">
                        <div class="item">面试题解析剖析</div>
                        <div class="item">补充UML，对象三要素等知识</div>
                        <div class="item">全部面试题的案例分析</div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="step-third-box">
                      <div class="step-n">03</div>
                      <div class="step-cc">系统设计System Design</div>
                      <div class="step-items">
                        <div class="item">铺垫大量前置知识</div>
                        <div class="item">针对零基础小白学起</div>
                        <div class="item">方方面面，大量知识内容</div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div class="container">
              <div class="step-third-card">
                <div class="step-title">算法面试三部曲</div>
                <div class="step-row">
                  <div class="step-sque">
                    <div class="step-sque-text">算法基础知识</div>
                    <div class="step-sque-text">题型技巧总结</div>
                    <div class="step-sque-text">LeetCode 千题视频讲解</div>
                  </div>
                  <div class="step-row-item sque1">
                    <div class="tt">算法基础知识</div>
                    <div class="desc">手写底层数据结构，到Java源码剖析，最后深化面试题目，一应俱全</div>
                  </div>
                  <div class="step-row-item sque2">
                    <div class="tt">题型技巧总结</div>
                    <div class="desc">算法技巧套路方法归纳总结，模版直接套用速成去面试</div>
                  </div>
                  <div class="step-row-item sque3">
                    <div class="tt">LeetCode 千题视频讲解</div>
                    <div class="desc">千题讲解的课程，超长的150小时+的课程</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="g-section" style="margin-bottom: 36px">
            <div class="g-header">
              <h3>
                3次模拟面试+不限次简历修改服务，直推大厂到求职中任何问题咨询
              </h3>
              <p>
                直面大厂一线面试官，为你创造更真实的场景化面试条件，提前熟悉标准的面试流程
              </p>
            </div>
            <div class="moni-container">
              <div class="moni-cell clearfix">
                <div class="moni-box">
                  <div class="hd">第一次面试</div>
                  <div class="bd">
                    <p>• 学习效果如何评估？</p>
                    <p>• 与大厂面试差距在哪里？</p>
                    <p>• 如何更有针对性的提升？</p>
                    <p>• 这些问题都将在面试中得到解决</p>
                  </div>
                </div>
                <div class="moni-box">
                  <div class="hd">第二次面试</div>
                  <div class="bd">
                    <p>• 首次面试问题是否解决？</p>
                    <p>• 刷题面试核心能力是否具备？</p>
                    <p>• 充分了解自己优势和劣势</p>
                    <p>• 为真实面试做好充足准备</p>
                  </div>
                </div>
                <div class="moni-box">
                  <div class="hd">简历修改</div>
                  <div class="bd">
                    <p>• 围绕大厂面试招聘需求</p>
                    <p>• 指导面试简历修改</p>
                    <p>• 快速高效的帮你理清思路</p>
                    <p>• 明确求职方向</p>
                  </div>
                </div>
                <div class="moni-box">
                  <div class="hd">第三次面试</div>
                  <div class="bd">
                    <p>• 对话经验丰富大厂面试官</p>
                    <p>• 你的能力将直接得到展现</p>
                    <p>• 严格标准化的面试流程</p>
                    <p>• 还原最真实的面试场景</p>
                  </div>
                </div>
                <div class="moni-box">
                  <div class="hd">大厂内推</div>
                  <div class="bd">
                    <p>• 通过一步步有针对性训练</p>
                    <p>• 经过层层面试筛选</p>
                    <p>• 求职班面试合格的同学</p>
                    <p>• 直接内推北美大厂</p>
                  </div>
                </div>
                <div class="moni-box">
                  <div class="hd">求职咨询服务</div>
                  <div class="bd">
                    <p>• 如何准备面试?</p>
                    <p>• 如何解决身份问题？</p>
                    <p>• 如何抉择offer？</p>
                    <p>• 有难题我们帮你解决</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="g-section gh-section">
            <div class="g-header">
              <h3>
                从求职规划、项目课程到刷题面试的全流程跟踪，做最坚实的求职后盾
              </h3>
              <p>1对1模式贯彻到底，只要你有问题，全程1对1回答</p>
            </div>
            <div class="gh-container">
              <div class="gh-cell">
                <div class="gh-box">
                  <div class="gh-icon">
                    <img src="../../assets/images/beimeivip/sv1.png" />
                  </div>
                  <h3>1对1求职规划</h3>
                  <p>
                    入学前导师咨询<br />1对1规划求职路径<br />针对个人情况单独调整
                  </p>
                </div>
                <div class="gh-box">
                  <div class="gh-icon">
                    <img src="../../assets/images/beimeivip/sv2.png" />
                  </div>
                  <h3>1对1工业项目</h3>
                  <p>
                    转专业简历缺CS项目<br />1月速成4-5个项目<br />不必再报额外项目课
                  </p>
                </div>
                <div class="gh-box">
                  <div class="gh-icon">
                    <img src="../../assets/images/beimeivip/sv3.png" />
                  </div>
                  <h3>1对1监督</h3>
                  <p>
                    班主任全程跟踪进度<br />1v1电话沟通<br />“看着”刷题，保证效果
                  </p>
                </div>
                <div class="gh-box">
                  <div class="gh-icon">
                    <img src="../../assets/images/beimeivip/sv4.png" />
                  </div>
                  <h3>1对1面试</h3>
                  <p>每月一次面试<br />1v1判断刷题情况<br />发现问题解决问题</p>
                </div>
                <div class="gh-box">
                  <div class="gh-icon">
                    <img src="../../assets/images/beimeivip/sv5.png" />
                  </div>
                  <h3>1对1课后咨询</h3>
                  <p>求职路任何疑问<br />1v1导师咨询<br />有问必答，坚实后盾</p>
                </div>
                <div class="gh-lg">全程1对1服务</div>
              </div>
            </div>
          </div>

          <div class="g-section bg-gray">
            <div class="g-header">
              <h3>同级别求职课程对比，我们的优势非常明显</h3>
              <p>同行都在做的是义务，没有做的才叫优势和服务</p>
            </div>
            <div class="container">
              <div class="level-vs">
                <div class="level-col">
                  <div class="level-td">项目课程</div>
                  <div class="level-td">LeetCode 1000题讲解</div>
                  <div class="level-td">23种设计模式</div>
                  <div class="level-td">CS基础知识</div>
                  <div class="level-td">录播模式</div>
                  <div class="level-td">1v1导师服务</div>
                  <div class="level-td">1v1电话监督</div>
                  <div class="level-td">终身内推</div>
                </div>
                <div class="level-col level-cson-col">
                  <div class="level-lg-td">CSON</div>
                  <div class="level-td">包含项目课，可写4-8个项目课</div>
                  <div class="level-td">1000题讲解</div>
                  <div class="level-td">全部讲全</div>
                  <div class="level-td">小白也听懂</div>
                  <div class="level-td">根据自己基础随时调整</div>
                  <div class="level-td">和讲课导师1v1沟通</div>
                  <div class="level-td">电话监督进度情况</div>
                  <div class="level-td">强推，国内外，终身內推</div>
                </div>
                <div class="level-col level-other-col">
                  <div class="level-lg-td">其他机构平台</div>
                  <div class="level-td">另需$5000-8000费用</div>
                  <div class="level-td">无此课程</div>
                  <div class="level-td">无此课程</div>
                  <div class="level-td">无此知识内容</div>
                  <div class="level-td">直播为主，基础差跟不上</div>
                  <div class="level-td">都是客服人员沟通</div>
                  <div class="level-td">大班课，没人管</div>
                  <div class="level-td">内推一次，或者不给内推</div>
                </div>
              </div>
            </div>
          </div>

          <div class="g-section bg-gray">
            <div class="g-header">
              <h3>海内外丰富内推资源库，帮你脱离求职困境，脱颖而出</h3>
              <p>环环紧扣的知识刷题体系，考点、非考点，高频、低频一目了然</p>
            </div>
            <div class="container">
              <el-row :gutter="30">
                <el-col :span="6">
                  <div class="zy-card hoverUp">
                    <div class="z-icon">
                      <img src="../../assets/images/beimeivip/zy1.png" alt="" />
                    </div>
                    <div class="z-tit">覆盖国内外内推资源</div>
                    <div class="z-desc">
                      海量内推资源随意选<br />
                      合格学员简历直达名企
                    </div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="zy-card hoverUp">
                    <div class="z-icon">
                      <img src="../../assets/images/beimeivip/zy2.png" alt="" />
                    </div>
                    <div class="z-tit">一套流程搞定大厂内推</div>
                    <div class="z-desc">
                      内推制度不透明导致内推流程繁琐<br />
                      一键内推轻松搞定一切
                    </div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="zy-card hoverUp">
                    <div class="z-icon">
                      <img src="../../assets/images/beimeivip/zy3.png" alt="" />
                    </div>
                    <div class="z-tit">严格筛选的内推人审查机制</div>
                    <div class="z-desc">
                      职位更新不及时，反馈效率低<br />
                      高效在线沟通直面内推人
                    </div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="zy-card hoverUp">
                    <div class="z-icon">
                      <img src="../../assets/images/beimeivip/zy4.png" alt="" />
                    </div>
                    <div class="z-tit">有反馈有监督的进度反馈</div>
                    <div class="z-desc">
                      告别反馈不及时错过最佳求职期<br />
                      每一个流程都有监督都有反馈
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="g-section fmag-section">
            <div class="g-header">
              <h3>FMAG级别10+公司内推强推，国内外大厂终身内推</h3>
              <p>往届学员强强联合，打造北美科技公司内推体系</p>
            </div>
            <div class="container">
              <el-row justify="center" type="flex">
                <el-col :span="8">
                  <div class="fm-box">
                    <div class="item">10+公司</div>
                    <div class="item">FMAG级别公司</div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="fm-box">
                    <div class="item">国内外公司</div>
                    <div class="item">一应俱全</div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="fm-box">
                    <div class="item">Senior员工</div>
                    <div class="item">高级别员工内推</div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="fm-box">
                    <div class="item">强推免掉电面</div>
                    <div class="item">直接Onsite</div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="fm-box">
                    <div class="item">终身内推</div>
                    <div class="item">Intern & New Grad & 跳槽</div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <course-description></course-description>
          <offer></offer>
          <free-system></free-system>
<!--          <div class="g-section leet-jj-section" id="discountPrice">-->
<!--            <a name="tt-price"> </a>-->
<!--            <div class="g-header">-->
<!--              <h3>价格方案</h3>-->
<!--            </div>-->
<!--            <div>-->
<!--              <div class="container">-->
<!--                <div class="gg-yh-panel sc-yh-panel clearfix">-->
<!--                  <div-->
<!--                    class="gg-card"-->
<!--                    style="margin: 0 auto !important; width: 900px"-->
<!--                    v-if="comboData && comboData.length > 0"-->
<!--                  >-->
<!--                    <el-row>-->
<!--                      <el-col :span="12">-->
<!--                        <div class="gg-yh-cell" style="margin: 0 auto">-->
<!--                          <div-->
<!--                            :class="[-->
<!--                              comboData.length > 1-->
<!--                                ? 'gg-yh-grid red'-->
<!--                                : 'gg-yh-grid',-->
<!--                            ]"-->
<!--                          >-->
<!--                            <div class="gg-price">-->
<!--                              ${{ comboData[0].usdprice }}-->
<!--                            </div>-->
<!--                            <div class="gg-y-price">-->
<!--                              ¥{{ comboData[0].price }}-->
<!--                            </div>-->
<!--                            &lt;!&ndash; <div-->
<!--                              class="gg-base-price"-->
<!--                              v-if="comboData[0].usddiscountPrice != 0"-->
<!--                            >-->
<!--                              原价：${{ comboData[0].usdprice }}-->
<!--                            </div> &ndash;&gt;-->
<!--                          </div>-->
<!--                          <div class="gg-yh-body">-->
<!--                            <div class="tip">-->
<!--                              立即报名，立即开始，前置课程提前学习<br />不浪费一分一秒，报名立刻学习-->
<!--                            </div>-->
<!--                            <ul class="gg-yh-item">-->
<!--                              <li>-->
<!--                                <i class="iconfont icon-dui"></i-->
<!--                                >全程以1v1为主，Edward老师带着刷题-->
<!--                              </li>-->
<!--                              <li>-->
<!--                                <i class="iconfont icon-dui"></i>1v1简历修改 &-->
<!--                                1v1模拟面试-->
<!--                              </li>-->
<!--                              <li>-->
<!--                                <i class="iconfont icon-dui"></i-->
<!--                                >1v1推荐项目，解决简历内容-->
<!--                              </li>-->
<!--                              <li>-->
<!--                                <i class="iconfont icon-dui"></i-->
<!--                                >1v1北美求职规划时间线-->
<!--                              </li>-->
<!--                              <li>-->
<!--                                <i class="iconfont icon-dui"></i-->
<!--                                >10+北美FMAG级别公司内推-->
<!--                              </li>-->
<!--                              <li>-->
<!--                                <i class="iconfont icon-dui"></i-->
<!--                                >短期突破2个月零基础400题-->
<!--                              </li>-->
<!--                              <li>-->
<!--                                <i class="iconfont icon-dui"></i-->
<!--                                >4个月学习+8个月求职跟踪-->
<!--                              </li>-->
<!--                              <li>-->
<!--                                <i class="iconfont icon-dui"></i-->
<!--                                >班课有效期：12个月-->
<!--                              </li>-->
<!--                            </ul>-->
<!--                            <div class="yh-foot">-->
<!--                              <el-button-->
<!--                                type="buy"-->
<!--                                @click="buyCombo"-->
<!--                                :class="[comboData.length > 1 ? 'red' : '']"-->
<!--                                >立即购买-->
<!--                              </el-button>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </el-col>-->
<!--                      <el-col :span="12">-->
<!--                        <div class="gg-yh-cell" style="margin: 0 auto">-->
<!--                          <div :class="['gg-yh-grid red']">-->
<!--                            <div class="gg-tag-discountTip">-->
<!--                              <img src="@/assets/imgs/discountTip.png" alt="" />-->
<!--                            </div>-->
<!--                            <div class="gg-price">-->
<!--                              $6999-->
<!--                            </div>-->
<!--                            <div class="gg-y-price">-->
<!--                              ¥45423.51-->
<!--                            </div>-->
<!--                          </div>-->
<!--                          <div class="gg-yh-body">-->
<!--                            <div class="body-yh">-->
<!--                              <div class="des">-->
<!--                                两种课程优惠模式-->
<!--                                <br />-->
<!--                                【团购价】2人报名即可团购-->
<!--                                <br />-->
<!--                                【早鸟价】前3人报名即可获得-->
<!--                                <br /><br />-->
<!--                                扫码添加客服微信进行咨询-->
<!--                                <br />-->
<!--                              </div>-->
<!--                              <img-->
<!--                                class="qrcode"-->
<!--                                :src="qrcode.imageUrl"-->
<!--                                alt=""-->
<!--                              />-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </el-col>-->
<!--                    </el-row>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
          <div class="g-section leet-jj-section" id="discountPrice">
            <a name="tt-price"> </a>
            <div class="g-header">
              <h3>价格方案</h3>
            </div>
            <div>
              <div class="container">
                <div class="gg-yh-panel sc-yh-panel clearfix">
                  <div
                      class="gg-card"
                      style="margin: 0 auto !important"
                      v-if="comboData && comboData.length > 0"
                  >
                    <el-row>
                      <el-col :span="comboData.length > 1 ? 12 : 24">
                        <div class="gg-yh-cell">
                          <div
                              :class="[
                              comboData.length > 1
                                ? 'gg-yh-grid red'
                                : 'gg-yh-grid',
                            ]"
                          >
<!--                            <div-->
<!--                                class="gg-tag-discountTip"-->
<!--                                v-if="comboData[0].discountPrice > 0"-->
<!--                            >-->
<!--                              <img src="@/assets/imgs/discountTip.png" alt="" />-->
<!--                            </div>-->
                            <div class="gg-price">
<!--                              ${{-->
<!--                                comboData[0].usddiscountPrice > 0-->
<!--                                    ? comboData[0].usdprice : comboData[0].usddiscountPrice-->
<!--                              }}-->
                              ${{comboData[0].usdprice}}
                            </div>
                            <div class="gg-y-price">
<!--                              ¥{{-->
<!--                                comboData[0].discountPrice > 0-->
<!--                                    ? comboData[0].price : comboData[0].discountPrice-->
<!--                              }}-->
<!--                              ¥50000-->
                              ¥{{comboData[0].price}}
                            </div>
                          </div>
                          <div class="gg-yh-body">
                            <div class="tip">
<!--                              立即报名，立即开始，前置课程提前学习<br />不浪费一分一秒，报名立刻学习-->
                              认真学习拿到offer近在咫尺<br />算法体系化，设计体系化
                            </div>
                            <ul class="gg-yh-item">
                              <li>
                                <i class="iconfont icon-dui"></i
                                >包含工业级项目，解决简历内容
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i
                                >1v1简历修改 & 1v1模拟面试
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i
                                >1v1全程跟踪教学
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i
                                >LeetCode 1-1000题视频讲解
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i
                                >面试技巧 & 答疑服务
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i
                                >算法体系化，设计体系化
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i
                                >10+北美FMAG级别公司内推，终身内推
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i
                                >班课有效期：1年
                              </li>
                            </ul>
                            <div class="yh-foot">
                              <el-button
                                  type="buy"
                                  @click="buyCombo"
                                  :class="[comboData.length > 1 ? 'red' : '']"
                              >立即购买
                              </el-button>
                            </div>
                          </div>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="TabActiveIndex == 'course_question'" class="questionArea">
          <presales-consultation :objectId="7"></presales-consultation>
        </div>

<!--        <courseMeal-->
<!--          v-if="TabActiveIndex == 'course_content'"-->
<!--          id="7"-->
<!--        ></courseMeal>-->
        <!-- <course-detail :TabActive="TabActiveIndex" :course-i-d="courseId" /> -->
		<course-detail v-if="TabActiveIndex == 'course_content'"/>
      </div>
    </div>
    <leetDialog></leetDialog>
  </div>
</template>

<script>
import leetDialog from "@/components/leetDialog.vue";
import courseMix from "../../utils/courseMix";
import courseDetail from "../../components/course/courseDetail2";
import Menus from "../../components/course/menu";
import offer from "./components/offer";
import freeSystem from "./components/freeSystem";
import presalesConsultation from "./components/presalesConsultation";
import polyVideo from "./components/polyVideo";
import courseDescription from "./components/courseDescription";
import courseMeal from "./components/courseMeal";
import pageSelect from "./components/pageSelect";
import pageStickySelect from "./components/pageStickySelect";
import helpClass from "./components/help.vue";
import { getCountDownClass } from "@/service/index";
import UML from "@/components/course/coursesComponents/UML";
import OOD from "@/components/course/coursesComponents/OOD";
import ObjectOriented from "@/components/course/coursesComponents/ObjectOriented";
import UMLDetail from "@/components/course/coursesComponents/UMLDetail";
import { getCourseBanner } from "@/service";

export default {
  components: {
    leetDialog,
    Menus,
    courseDetail,
    offer,
    freeSystem,
    polyVideo,
    courseDescription,
    presalesConsultation,
    courseMeal,
    pageSelect,
    pageStickySelect,
    helpClass,
    UML,
    OOD,
    ObjectOriented,
    UMLDetail,
  },
  mixins: [courseMix],
  data() {
    return {
      stickyToggle: false,
      stickyEnabled: true,
      videoShow: false,
      bantxt: "北美SDE求职旗舰班",
      dr: 7,
      br: 7,
      txShow: true,
      curId: 0,
      tradio: 3,
      bzid: 0,
      Pselect: "1",
      img: require("../../assets/images/beimeivip/banner.jpg"),
      form: {
        region: "v1",
      },
      activeNames: ["1"],
      TabActiveIndex: "course_intro",
      menuList: [
        { name: "班课介绍", index: "0", tag: "course_intro" },
        // { name: "班课套餐", index: "1", tag: "course_meal" },
        { name: "班课大纲", index: "1", tag: "course_content" },
        // { name: "班课模式", index: "2", tag: "course_mode" },
        // {name: "用户评价", index: "1", tag: "course_comment"},
        // { name: "售前咨询", index: "2", tag: "course_question" },
      ],
      currentIndex: "0",
      recommendClasses: [], //相关课程推荐byzuhuimin
      helpVisible: false,
      countDownText: "",
      countDownTimeStamp: -1,
      countDays: 0,
      countHours: 0,
      countMinutes: 0,
      countSeconds: 0,
      qrcode: {},
    };
  },
  created() {
    // this.setEndTime(10);
    console.log(this.startTime);
    this.getCountDownClass();
  },
  mounted() {
    window.scrollTo(0, 0);
    getCourseBanner(2).then((res) => {
      if (res.success) {
        this.qrcode = res.result[0];
      }
    });
  },
  methods: {
    handleCommand(command) {
      // this.$message('click on item ' + command);
      this.bantxt = command;
      console.log(command);
      switch (command) {
        case "10天LeetCode经典100题":
          this.$router.push("/course/1");
          break;
        case "求职面试算法班":
          this.$router.push("/course/4");
          break;
        case "求职面试刷题班":
          this.$router.push("/course/5");
          break;
        case "北美SDE算法速成班":
          this.$router.push("/course/3");
          break;
        case "LeetCode千题视频讲解":
          this.$router.push("/course/2");
          break;
        case "北美SDE求职速成班":
          this.$router.push("/course/6");
          break;
        case "北美SDE求职旗舰班":
          this.$router.push("/course/7");
          break;
      }
    },
    changePage(index) {
      this.$router.push(`/course/${index}`);
    },
    onStick(data) {
      console.log(data);
      this.stickyToggle = data.sticked;
    },
    enter: function (e) {
      console.log(e);
      this.dr = e;
    },
    brenter: function (e) {
      console.log(e);
      this.br = e;
    },
    echange(val) {
      this.bzid = val;
    },
    setActiveItem(index) {
      this.$refs.carousel.setActiveItem(index);
    },
    jumptoPrice() {
      this.TabActiveIndex = "course_intro";
      this.currentIndex = "0";
      this.$nextTick(() => {
        document.documentElement.scrollTop =
          this.$el.querySelector("#discountPrice").offsetTop - 60;
      });
    },
    timeCountDown(endDateStr) {
      if (endDateStr == -1) {
        return;
      }
      //结束时间
      var endDate = new Date(endDateStr);
      //当前时间
      var nowDate = new Date();
      //相差的总秒数
      if (endDate < nowDate) {
        window.location.reload();
      }
      var totalSeconds = parseInt((endDate - nowDate) / 1000);
      //天数
      var days = Math.floor(totalSeconds / (60 * 60 * 24));

      //取模（余数）
      var modulo = totalSeconds % (60 * 60 * 24);

      //小时数
      var hours = Math.floor(modulo / (60 * 60));

      modulo = modulo % (60 * 60);
      //分钟
      var minutes = Math.floor(modulo / 60);
      //秒

      var seconds = modulo % 60;

      this.countDays = days;
      this.countHours = hours;
      this.countMinutes = minutes;
      this.countSeconds = seconds >= 10 ? seconds : `0${seconds}`;

      setTimeout(() => {
        this.timeCountDown(endDateStr);
      }, 1000);
    },
    getCountDownClass() {
      getCountDownClass(7).then((res) => {
        if (res.success) {
          this.countDownTimeStamp = res.result;
          this.timeCountDown(this.countDownTimeStamp);
        }
      });
    },
  },
};
</script>

<style scoped="scoped" lang="scss">
@import url("../../assets/css/courses/common.css");
@import url("../../assets/css/courses/7-UsVIPCourse.css");
</style>
